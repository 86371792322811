import { ROUTES } from 'common/constant';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

export default function Custom404() {
  const router = useRouter();
  useEffect(() => {
    router.push(ROUTES.LANDING);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <h1>404 - Page Not Found</h1>;
}
